import { type NavbarConfigSectionColumnFragment } from "@gql-schema";
import { DesktopSubmenuColumnRoot } from "@ui/components/NavBar/styles/DesktopSubmenuColumnRoot";
import { FC } from "react";
import { DesktopSubmenuItem } from "./DesktopSubmenuItem";

interface DesktopSubmenuColumnProps {
    data: NavbarConfigSectionColumnFragment;
    isGreen?: boolean;
}

export const DesktopSubmenuColumn: FC<DesktopSubmenuColumnProps> = ({ data, isGreen }) => {
    return (
        <DesktopSubmenuColumnRoot as={data.items.length === 0 ? "div" : "ul"} $isGreen={isGreen}>
            {data.items.map((item, index) => (
                <DesktopSubmenuItem isTitle={index === 0} key={index} label={item.label} href={item.href} />
            ))}
        </DesktopSubmenuColumnRoot>
    );
};
