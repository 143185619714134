import { COLORS } from "@ui/theme/colors";
import { ZINDEXES } from "@ui/theme/zIndexes";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface DesktopSubmenuPopUpRootProps extends HTMLAttributes<HTMLDivElement> {
    $border?: boolean;
}

export const DesktopSubmenuPopUpRoot = styled("div")<DesktopSubmenuPopUpRootProps>(
    ({ $border }) => css`
        background-color: ${COLORS.white};
        border-bottom: ${$border ? `1px solid ${COLORS.green.default}` : null};
        border-top: ${$border ? `1px solid ${COLORS.green.default}` : null};
        display: flex;
        left: 0;
        position: fixed;
        right: 0;
        z-index: ${ZINDEXES.fixed};
    `,
);
