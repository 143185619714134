import { type NavbarConfigItemFragment } from "@gql-schema";
import { Link } from "@ui/components/Link/Link";
import { BodyText } from "@ui/components/Typography/BodyText";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { FC, memo } from "react";
import { css } from "styled-components";
import { useResetPopUpDataAtom } from "./popUpDataAtom";

interface DesktopSubmenuItemProps extends NavbarConfigItemFragment {
    isTitle?: boolean;
}

const LINK_STYLE = css`
    &:hover {
        text-decoration: underline;
    }
`;

const DesktopSubmenuItemComponent: FC<DesktopSubmenuItemProps> = (props) => {
    const resetDataKey = useResetPopUpDataAtom();

    try {
        return (
            <li>
                <BodyText $lineHeight={(20 / 16).toString()}>
                    <Link
                        href={props.href}
                        $fontWeight={props.isTitle ? TYPOGRAPHY.fontWeight.bold : TYPOGRAPHY.fontWeight.regular}
                        $css={LINK_STYLE}
                        $textDecoration="none"
                        onClick={resetDataKey}
                    >
                        {props.label}
                    </Link>
                </BodyText>
            </li>
        );
    } catch {
        return null;
    }
};

export const DesktopSubmenuItem = memo(DesktopSubmenuItemComponent);

DesktopSubmenuItem.displayName = "DesktopSubmenuItem";
