import { COLORS } from "@ui/theme/colors";
import { rem } from "@uxf/styles/utils/rem";
import styled, { css } from "styled-components";

interface DesktopSubmenuColumnRootProps {
    as?: "div" | "ul";
    $isGreen?: boolean;
}

export const DesktopSubmenuColumnRoot = styled("ul")<DesktopSubmenuColumnRootProps>(
    ({ $isGreen }) => css`
        background-color: ${$isGreen ? COLORS.green.secondary : null};
        padding-bottom: ${rem(48)};
        padding-top: ${rem(32)};

        &:not(:first-child) {
            padding-left: ${rem(16)};
        }

        &:not(:last-child) {
            padding-right: ${rem(16)};
        }

        * + * {
            margin-top: ${rem(16)};
        }
    `,
);
