import { type NavbarConfigSectionColumnFragment, type NavbarConfigSectionFragment } from "@gql-schema";
import { hideVisually } from "@ui/mixins/hideVisually";
import { FC, PropsWithChildren, memo } from "react";
import { SeoSubmenuLink } from "./SeoSubmenuLink";
import { hasSubmenu } from "./hasSubmenu";

function renderColumn(data: NavbarConfigSectionColumnFragment, i: number) {
    if (data.items.length === 0) {
        return null;
    }

    return (
        <li key={i}>
            {data.items.length > 0 && (
                <ul>
                    {data.items.map((item, index) => (
                        <SeoSubmenuLink key={index} label={item.label} href={item.href} />
                    ))}
                </ul>
            )}
        </li>
    );
}

interface SeoSubmenuProps {
    data: NavbarConfigSectionFragment;
}

const SeoSubmenuComponent: FC<PropsWithChildren<SeoSubmenuProps>> = ({ data }) => {
    if (!hasSubmenu(data)) {
        return null;
    }

    return (
        <ul aria-hidden="true" style={hideVisually}>
            {data.columns.map((column, index) => renderColumn(column, index))}
        </ul>
    );
};

export const SeoSubmenu = memo(SeoSubmenuComponent);

SeoSubmenu.displayName = "SeoSubmenu";
