import { type NavbarConfigSectionFragment } from "@gql-schema";
import { Box } from "@ui/components/Box/Box";
import { DesktopSubmenuPopUpRoot } from "@ui/components/NavBar/styles/DesktopSubmenuPopUpRoot";
import { COLORS } from "@ui/theme/colors";
import { CONTAINERS } from "@ui/theme/containers";
import { useRafState } from "@uxf/core/hooks/useRafState";
import { FC, memo, PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { DesktopSubmenuContent } from "./DesktopSubmenuContent";
import { hasSubmenu } from "./hasSubmenu";
import { useResetPopUpDataAtom } from "./popUpDataAtom";

interface DesktopSubmenuPopUpProps {
    border?: boolean;
    data: NavbarConfigSectionFragment;
}

const DesktopPopUpComponent: FC<PropsWithChildren<DesktopSubmenuPopUpProps>> = ({ border, data }) => {
    const anchorNode = useRef<HTMLDivElement>(null);
    const [offsetTop, setOffsetTop] = useRafState<number | undefined>(undefined);
    const resetDataKey = useResetPopUpDataAtom();

    useEffect(() => {
        function handleOffset() {
            const anchorElement = anchorNode.current;
            if (anchorElement) {
                const parentElement = anchorElement.parentElement;
                if (parentElement) {
                    setOffsetTop(parentElement.getBoundingClientRect().bottom);
                }
            }
        }

        handleOffset();
        window.addEventListener("scroll", handleOffset);
        return () => {
            window.removeEventListener("scroll", handleOffset);
        };
    }, [setOffsetTop]);

    return hasSubmenu(data) ? (
        <>
            <div ref={anchorNode} role="none" style={{ alignSelf: "stretch" }} />
            {createPortal(
                <DesktopSubmenuPopUpRoot
                    $border={border}
                    aria-hidden
                    style={offsetTop ? { top: offsetTop } : undefined}
                    onMouseLeave={resetDataKey}
                >
                    <Box $flexGrow={1} />
                    <Box
                        $flexShrink={0}
                        $maxWidth={Object.values(CONTAINERS)}
                        $mx="auto"
                        $px={[24, null, 0]}
                        $width="100%"
                    >
                        <DesktopSubmenuContent data={data} />
                    </Box>
                    <Box
                        $backgroundColor={data.columns.length > 3 ? COLORS.green.secondary : undefined}
                        $flexGrow={1}
                    />
                </DesktopSubmenuPopUpRoot>,
                document.body,
            )}
        </>
    ) : null;
};

export const DesktopPopUp = memo(DesktopPopUpComponent);

DesktopPopUp.displayName = "DesktopSubmenuPopUp";
